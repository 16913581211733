





























































































































/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component } from 'vue-property-decorator';
import WidgetMixins from '../../mixins/WidgetMixins';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { mixins } from 'vue-class-component';
import { IResetPassword } from '@/types/types';
extend('required', {
  ...required,
  message: 'Field is required',
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ResetPassword extends mixins(WidgetMixins) {
  bg: string = require('@/assets/images/dashboard-1.png');
  show: boolean = false;

  code: string = '';

  newPassword: string = '';
  confirmPassword: string = '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldState: any = {
    newPassword: false,
    confirmPassword: false,
  };

  resetPassword(): void {
    let payload: IResetPassword = {
      token: decodeURIComponent(this.$route.query.token as string),
      newPassword: this.newPassword,
      confirmPassword: this.confirmPassword,
    };

    this.$store.dispatch('auth/resetPassword', payload);
  }

  changeState(state: string): void {
    this.$set(this.fieldState, state, !this.fieldState[state]);
  }
}
